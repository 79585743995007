body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


.loading--content {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255,255,255,.65);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

// .BrainhubCarousel li.BrainhubCarouselItem {
//   min-width: 100px!important;
//   width: 100px!important;
//   padding: 10px;
// }

// li.BrainhubCarouselItem.BrainhubCarouselItem img {
//   border-radius: 5px;
//   box-shadow: 0 0 10px rgba(0,0,0,0.25);
// }

// .BrainhubCarousel__trackContainer {
//   width: calc(100% + 40px);
//   }

// .BrainhubCarousel__container {
//   margin: 0 -40px;
// }
// .BrainhubCarousel__container {
//   width: calc(100% + 80px)!important;
// }
// .BrainhubCarousel {
//   min-width: 100%;
// }

// .BrainhubCarousel {
//   position: relative;
// }

// .BrainhubCarousel:before {
//   content: "";
//   width: 60px;
//   height: 100%;
//   position: absolute;
//   background: linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
//   top: 0;
//   left: 0;
//   z-index: 99;
// }

// .BrainhubCarousel:after {
//   content: "";
//   width: 60px;
//   height: 100%;
//   position: absolute;
//   background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
//   top: 0;
//   right: 0;
//   z-index: 99;
// }

// .BrainhubCarousel li.BrainhubCarouselItem img {
//   width: 100%;
//   height: 100%;
//   object-fit: contain;
// }


.not-found,
.error-page .message.message--error>div {
  max-width: 900px;
  display: block;
  margin: 0 auto;
  padding: 50px;
  background: #f5f5f5;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  width: 100%;
}

.error-page .message.message--error>div {
  position: relative!important;
  margin-top: 30px;
  top: 0;
  background-color: #f5f5f5!important;
  left: 0;
  bottom: 0;
  right: 0;
  padding-right: 400px;
}

.error-page .message.message--error>div:before {
  content: "";
}

.card>div h4 + p {
  margin: 15px 0;
  font-size: 14px;
}

.not-found .not-found--image,
.error-page .message.message--error>div:before {
  background-image: url('./assets/images/error.png');
  background-repeat: no-repeat;
  width: 300px;
  height: 300px;
  display: block;
  background-size: contain;
  position: absolute;
  right: 20px;
  top: 0;
}


html.error-page {
  background-image: none;
}


.language-flag {
  border-radius: 50%;
  width: 32px;
  border: 1px solid rgba(256,256,256,.3);
  height: 32px;
  cursor: pointer;
}



@media (max-width: 767px) {


  footer.footer {
    margin: 50px -20px 0 -20px;
    width: calc(100% + 40px);
}
  ul.wizard {
    padding: 50px 20px;
  }

  ul.wizard span.wizard--name {
      display: none;
  }

  ul.wizard 
  li.actived span.wizard--name {
      display: flex;
  }

  .header header.header--top .logo {
     // background-position: center!important;
  }

  div#root {
      padding: 0 20px;
  }


  .header header.header--top {
    margin: 0 -20px 30px -20px;
    width: calc(100% + 40px)!important;
    /* display: flex; */
  }

  html {
      background-image: none!important;
  }
  .card {
    padding: 40px 25px!important;
  }

  .card .BrainhubCarousel__container {
    width: calc(100% + 40px)!important;
    margin: 0 -20px!important;
  }

  .not-found .not-found--image,
  .error-page .message.message--error>div:before {
    position: static;
    width: 100%;
    height: 185px;
    background-position: center;
  }

  .not-found,
  .error-page .message.message--error>div {
    width: 100%;
    max-width: none;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    padding-right: 20px;
  }
}
