html,
body,
#root,
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  min-height: 100vh;
}

.content-wrapper {
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: left bottom;

  .iberia & {
    background-image: url('../images/iberia.png');
  }

  .vueling & {
    background-image: url('../images/vueling.png');
  }

  @media (max-width: 767px) {
    background-image: none!important;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.list--container {
  position: absolute;
  right: 5px;
  border-radius: 5px;
  display: block;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, .15);
  background: #fff;
  z-index: 10;
}

.header-title {
  margin: 50px auto;
  display: block;
  max-width: 400px;
}

.header header.header--top {
  width: 100%;
  min-height: 60px;

  .iberia & {
    background: #D7192D;
  }

  .vueling & {
    background: #fc0;
  }
}

.header header.header--top .container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.header header.header--top .logo {

  .iberia & {
    background-image: url('../images/logo-iberia.svg');
  }

  .vueling & {
    background-image: url('../images/logo-vueling.png');
  }
}

.header-title {
  margin: 50px auto;
  display: block;
  max-width: 400px;
}

.header header.header--top .logo {
  background-repeat: no-repeat;
  background-size: 125px;
  width: 100%;
  height: 60px;
  max-width: 1200px;
  margin: 0 auto;
  background-position: center left;
}

.header header.header--top {
  margin-bottom: 30px;
}

h2.header--title {
  margin: 0 auto;
  display: block;
  max-width: 1200px;
  text-align: center;
}

.loading h2 {
  display: block;
  margin: 0 auto;
  font-size: 0;
}

.content-wrapper {
  min-height: calc(100vh - 100px);
}

footer:before {
  content: "";
  display: block;
  width: 100%;
  height: 50px;
  position: absolute;
  top: -100%;
  .iberia & {
    background: linear-gradient(179deg, rgba(255,0,0,0) 45%, #fdd1d6 46%);
  }

  .vueling & {
    background: linear-gradient(179deg, rgba(255,0,0,0) 45%, #ffe67f 46%);
  }
}

footer.footer {
    position: relative;
}

footer.footer {
  display: block;
  width: 100%;
  margin: 0;
  margin-top: 50px;
  height: 50px;
  .iberia & {
    background: #fdd1d6;
  }

  .vueling & {
    background: #ffe67f;
  }
  .footer-content {
    // border: 1px solid blue;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    padding: 15px;
    position: relative;
  }
  .legal {
    // border: 1px solid red;
    position: absolute;
    width: 260px;
    height: 45px;    
    top: 0;
    right: 0;
    padding: 14px 0;
    text-align: left;
    text-decoration: inherit !important;
  }

  .legal a {
    color: inherit;
    text-decoration: inherit;
  }

  .legal button {
    font-family: inherit !important;
    border-style: none !important;
    font-size: 12px !important;
    .iberia & {
      background-color: #fdd1d6 !important;
    }
    .vueling & {
      background-color: #ffe67f !important;
    }
    // color: #4d4d4d !important;
    cursor: pointer !important;
    margin: 0 0 0 30px !important;
  }
}

#onetrust-consent-sdk .privacy-notice-link {
  .iberia & {
    --href-override: 'https://{0}sta001equipajes.blob.core.windows.net/assets/pdfs/privacy_policy/Pol%C3%ADtica%20de%20Privacidad_IBE.pdf';
  }
  .vueling & {
    --href-override: 'https://{0}sta001equipajes.blob.core.windows.net/assets/pdfs/privacy_policy/Pol%C3%ADtica%20de%20Privacidad_VLG.pdf';
  }
}

@media (max-width: 767px) {

  footer.footer {
    margin: 50px -20px 0 -20px;
    width: calc(100% + 40px);
}
}

body #root {
  min-height: 100vh;
  height: 100vh;
  position: relative;
}



ul.wizard {
  display: flex;
  justify-content: space-between;
  padding: 50px 0 40px;
  margin: auto;
  max-width: 400px;
}

ul.wizard li {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: nowrap;
  width: 100%;
  padding: 0;
  position: relative;
}

ul.wizard li:first-child {
  width: auto;
}

ul.wizard li:first-child:before {
  display: none;
}

ul.wizard li:first-child span {
  width: 40px;
}

ul.wizard li span {
  border-radius: 100px;
  height: 40px;
  width: 100%;
  max-width: 40px;
  background: #999;
  color: #fff;
  border: 3px solid #999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  z-index: 2;
  transition: all 0.45s;
}

ul.wizard li:before {
  content: "";
  height: 2px;
  background: #999;
  width: 100%;
  display: block;
}

ul.wizard li:after {
  width: 0px;
  content: "";
  position: absolute;
  height: 3px;
  transition: all 0.6s;
  left: 0;
  z-index: 1;

  .iberia & {
    background: #D7192D;
  }

  .vueling & {
    background: #fc0;
  }
}

ul.wizard li.actived:after {
  width: 100%;
}

ul.wizard li.actived:before {
  color: #fff;
}

ul.wizard li.actived span {
  color: #fff;
  transition-delay: 0.45s;
  font-weight: bold;

  .iberia & {
    border-color: #D7192D;
    background: #D7192D;
  }

  .vueling & {
    border-color: #fc0;
    background: #fc0;
  }
}

ul.wizard span span.wizard--name {
  position: absolute;
  font-size: 10px !important;
  font-weight: bold;
  //text-transform: capitalize;
  bottom: -40px !important;
  text-align: center !important;
  background: transparent !important;
  border: 0 !important;
  color: #333 !important;
}

.lds-roller {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin: -4px 0 0 -4px;

  .iberia & {
    background: #D7192D;
  }

  .vueling & {
    background: #fc0;
  }
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.language-element {
  cursor: pointer;
  margin-bottom: 20px;
}

.language-element:hover {
  opacity: 0.8;
}

.language-element div span:first-child {
  width: 35px;
  height: 35px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  background-image: url('../images/es.svg');
  background-size: contain;
}

.language-element #es-ES span:first-child {
  background-image: url('../images/es.svg');
}

.language-element #en-US span:first-child {
  background-image: url('../images/en.svg');
}

.language-element #de-DE span:first-child {
  background-image: url('../images/de.svg');
}

.language-element #fr-FR span:first-child {
  background-image: url('../images/fr.svg');
}

.language-element #it-IT span:first-child {
  background-image: url('../images/it.svg');
}

.language-element #pt-PT span:first-child {
  background-image: url('../images/pt.svg');
}

.card {
  max-width: 575px;
  padding: 40px;
  border-radius: 5px;
  display: block;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, .15);
  margin: 30px auto;
  width: 100%;
  background: #fff;
}

.language-element>div {
  padding: 10px;
  border-radius: 5px;
  transition: 0.2s all ease-in-out;
}

.language-element:hover>div {
  background: #f5f5f5;
}

.ea-input {
  display: inline-block;
  width: 100%;
  margin: 10px 0;
}

.ea-input label {
  font-size: 14px;
  display: block;
  color: #444;
}

.ea-input input,
.ea-input textarea {
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  margin-top: 5px;
}

select {
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  margin-top: 5px;
}

.ea-input input.error {
  border-color: #e74c3c;
}

.ea-input .react-datepicker-wrapper {
  width: 100%;
}

.ea-input span.error {
  color: #e74c3c;
  font-size: 14px;
  margin-top: 5px;
  display: block;
}


.react-datepicker-popper {
  z-index: 999;
}

.btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  &.right-align {
    justify-content: flex-end;
  }
}

@media (max-width: 767px) {
  .btn-wrapper .btn + .btn {
    margin-left: 10px;
  }
}


.btn.btn--primary {
  border: 0;
  border-radius: 5px;
  padding: 12px 25px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  outline: 0;
  transition: 0.2s ease-in-out all;

  .iberia & {
    background: #D7192D;
  }

  .vueling & {
    background: #fc0;
  }
  &:last-child {
    @media (max-width: 767px) {
      width: 100%
    }
  }
}

.btn.btn--primary:hover {
  border: 0;
  border-radius: 5px;
  padding: 12px 25px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;

  .iberia & {
    background: #a8191a;
  }

  .vueling & {
    background: #e9bb01;
  }
}

.btn.btn--primary[disabled] {
  opacity: .3;
  cursor: not-allowed;

  .iberia & {
    background: #584141;
  }

  .vueling & {
    background: #615d4b;
    color: white;
  }
}

.btn.btn--secondary {
  background: #fff;
  border-radius: 5px;
  padding: 10px 22px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  transition: 0.2s ease-in-out all;

  .iberia & {
    color: #D7192D;
    border: 2px solid #D7192D;
  }

  .vueling & {
    color: #4d4d4d;
    border: 2px solid #fc0;
  }
}

.btn.btn--secondary:hover {
  .iberia & {
    background: rgba(215, 25, 44, 0.1);
  }

  .vueling & {
    background: rgba(255, 204, 0, 0.1);
  }
}

.sub-description {
  font-size: 80%;
  margin: 10px 0;
}

.baggage-select {
  width: 100%;
  display: block;
  margin-top: 20px;
}

.baggage-select ul.BrainhubCarousel__dots {
  display: flex;
}

.baggage-select ul.BrainhubCarousel__dots li button img {
  width: 75px;
}

.baggage-select ul.BrainhubCarousel__dots li button {
  padding: 0;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  overflow: hidden;
}

.resume p span:first-child {
  font-weight: bold;
  margin-right: 5px;
}

.resume p {
  margin-top: 20px;
}

.message.message--error>div {
  padding: 20px;
  background: #ffeae8;
  width: auto;
  display: inline-block;
  border-radius: 5px;
  color: #e74c3c;
  position: fixed;
  right: 20px;
  bottom: 20px
}

.message.message--error button#btn-close-error {
  margin-top: 20px;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #e74c3c;
  background: #f3e1df;
  border: 2px solid #e74c3c;
  border-radius: 5px;
  cursor: pointer;
}

.message.message--error button#btn-close-error:hover {
  background: #e74c3c;
  color: #fff;
}


.images-size {
  width: 250px;
}


.active_color {
  border: 2px solid white;
  box-shadow: 4px 3px 5px 0px rgba(0,0,0,0.5);
  margin-bottom: 5px;
}


.no_active_color {
  margin-bottom: 5px;
}


li.BrainhubCarouselItem img {
  height: 280px;
  object-fit: cover;
  opacity: 0.4;
  @media (max-width: 767px) {
      height: 120px;
  }
}

li.BrainhubCarouselItem.BrainhubCarouselItem--active img {
  opacity: 1;
  cursor: pointer;
}
.color-box-item {
  width: 30px;
  height: 30px;
  display: inline-block;
  margin: 10px;
  border-radius: 50%;
  &.no_active_color {
    display: none;
  }

}

.color-box {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  margin: 20px 0;
  .color-item {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 0;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    &.active_color {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    }
  }
}

.item-name,
.item-description {
  font-weight: bold;
  font-size: 18px;
  margin: 20px auto;
  width: 100%;
  display: block;
  text-align: center;
}
.item-description {
  font-size: 14px;
  font-weight: normal;
}

.images-size-box {
  display: flex;
  justify-content: space-between;
}

[data-testid="selectionbaggage"] h4 {
  margin-top: 25px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}


.BrainhubCarousel__container {
  width: calc(100% + 120px);
  margin-left: -60px;
}

.BrainhubCarousel__arrows {
  border-radius: 50%;
  .iberia & {
    background: #D7192D;
    &:hover {
      background: #b41323;
    }
  }

  .vueling & {
    background: #fc0;
    &:hover {
      background: #dbb002;
    }
  }
}

.language-header span.language-country {
  display: inline-block;
  color: #fff;
}

img.language-flag {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.language-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
}

.language-header:hover {
  background: rgba(0,0,0,0.1);
  cursor: pointer;
}

.ea-input-address-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.ea-input-address-box .ea-input:first-child {
  width: 75%;
  margin-left: 0;
}

.ea-input-address-box .ea-input {
  width: 25%;
  margin-left: 10px;
}

input[id*="precio-estimado-maleta"] {
  // background-image: url('../../assets/images/euro.svg');
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: 98% center;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
input[type=number] { -moz-appearance:textfield; }

p.error.error-text {
  margin-top: 20px;
  color: red;
  font-weight: bold;
  font-size: 14px;
}
label.ea-text-label {
  display: block;
  font-size: 14px;
  margin-top: 5px;
}
.ea-input-box-two {
  display: flex;
}

.ea-input-box-two .ea-input {
  margin-left: 15px;
}

.ea-input-box-two .ea-input:first-child {
  margin-left: 0;
}

.preferencia-box .ea-input {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.preferencia-box .ea-input input {
  width: auto;
  margin-right: 10px;
  margin-top: 3px;
  cursor: pointer;
}

textarea {
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  margin-top: 5px;
  min-height: 100px;
}

label {
  font-size: 14px;
  display: block;
  color: #444;
}

.condiciones-box .ea-input {
  display: flex;
  flex-direction: row-reverse;
  margin: 20px 0;
}

.condiciones-box .ea-input input {
  width: auto;
  margin-right: 10px;
  cursor: pointer;
}


// STORES
ul.store-list {
  list-style: none;
  padding: 0;
}

ul.store-list li.store-item {
  box-shadow: 0 0 10px rgba(0,0,0,.15);
  border-radius: 5px;
  margin: 20px 0;
  padding: 20px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
}

ul.store-list p.store-address {
  display: none;
}

ul.store-list p.borrowerAssistanceId {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
}

ul.store-list p.commercialName {
  font-size: 18px;
  margin-bottom: 15px;
}

.images-size-box .images-size {
  width: 100%;
}

.images-size-box .images-size img {
  width: 100%;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  border-radius: 5px;
}

.images-size-box .images-size img:hover {
  transform: scale(0.95);
  box-shadow: 0 0 10px rgba(0,0,0,.15);
}

.detail-img {
  width: 100%;
  position: relative;
}

.detail-img img {
  width: 100%;
}

.detail-img .close-modal {
  font-size: 100px;
  position: absolute;
  right: 0;
  cursor: pointer;
  font-weight: 100;
  line-height: 55px;
}

.card a {
  color: inherit;
  font-weight: 600;
  text-decoration: none;
  border-bottom: 2px dotted;
  transition: 0.2s ease-in-out;
  &:hover {
    padding-bottom: 2px;
  }
}

.card.choose-store div {
  margin-bottom: 10px;
}

.card.choose-store ul {
  list-style: none;
}

.card.choose-store ul li {
  margin-bottom: 10px;
}
